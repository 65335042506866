import React from 'react'
import {useSpring, animated} from 'react-spring'
import loadable from '@loadable/component'
import Colors from '../../content/colors.json'


const Tile = (props) => {
  const spring_opts = useSpring({
    opacity: 1,
    from: {opacity: 0},
    delay: (props.delay || (400 + Math.floor(Math.random()*7200))),
    config: {duration: (props.duration || 2400)}
  });

  let TileIcon;
  let tileNum = 0;
  if(props.path) {
    TileIcon = loadable(() => import(`${props.path}`));
  } else {
    tileNum = Math.ceil(Math.random()*52);
    TileIcon = loadable(() => import(`../media/img/icons/Asset${tileNum}`));
  }


  const generateColor = () => {
    if(props.color) {
      return props.color;
    }
    const palette = props.palette || "generic"
    const index = Math.floor(Math.random()*Colors[palette].length)
    return Colors[palette][index];
  }

  const shouldShow = Math.random() > (props.ratio || 0);

    return (
      <animated.figure
      style={{...spring_opts, ...props.style}}
      className={`grid__tile ${props.className ? props.className : ''} tile--${tileNum}`}>

      {shouldShow && <TileIcon style={{'fill':`#${generateColor()}`}}/>}
      </animated.figure>
    )

}

export default Tile
